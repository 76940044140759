import BeforeSidebar from "./components/BeforeSidebar";
import AfterSidebar from "./components/AfterSidebar";
import BeforeSticky from "./components/BeforeSticky";
import AfterSticky from "./components/AfterSticky";
import BeforePageList from "./components/BeforePageList";
import BeforePagination from "./components/BeforePagination";
import AfterPageList from "./components/AfterPageList";
import BeforePage from "./components/BeforePage";
import BeforePageSection from "./components/BeforePageSection";
import AfterPage from "./components/AfterPage";
import AfterFooter from "./components/AfterFooter";

export default ({ Vue }) => {
  Vue.component("MyBeforeSidebar", BeforeSidebar);
  Vue.component("MyAfterSidebar", AfterSidebar);
  Vue.component("MyBeforeSticky", BeforeSticky);
  Vue.component("MyAfterSticky", AfterSticky);
  Vue.component("MyBeforePageList", BeforePageList);
  Vue.component("MyBeforePagination", BeforePagination);
  Vue.component("MyAfterPageList", AfterPageList);
  Vue.component("MyBeforePage", BeforePage);
  Vue.component("MyBeforePageSection", BeforePageSection);
  Vue.component("MyAfterPage", AfterPage);
  Vue.component("MyAfterFooter", AfterFooter);
};
